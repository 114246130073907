import { Button } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import { joinSectionStyles } from '..';
import { ArrowRight } from 'assets/icons/ArrowRight';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';

export const JoinSection: React.FC = () => {
  const photosQuery = graphql`
    query {
      image1: file(relativePath: { eq: "joinSectionPicture1.png" }) {
        id
        childImageSharp {
          fluid(quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      image2: file(relativePath: { eq: "joinSectionPicture2.png" }) {
        id
        childImageSharp {
          fluid(quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const img1 = useStaticQuery(photosQuery).image1;
  const img2 = useStaticQuery(photosQuery).image2;

  return (
    <div css={joinSectionStyles.root}>
      <InView threshold={0.2} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.h2
            ref={ref}
            initial={{ opacity: 0, y: '-20px' }}
            animate={{ opacity: inView ? 1 : 0, y: 0 }}
            transition={{ ease: 'linear', duration: 0.5, delay: 0.2 }}
            css={joinSectionStyles.sectionTitle}
          >
            Interested in joining?
          </motion.h2>
        )}
      </InView>
      <div css={joinSectionStyles.content}>
        <InView threshold={0.2} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '-20px' }}
              animate={{ opacity: inView ? 1 : 0, y: 0 }}
              transition={{ ease: 'linear', duration: 0.5, delay: 0.4 }}
            >
              <div css={joinSectionStyles.sectionWrapper}>
                <GatsbyImage
                  {...img1.childImageSharp}
                  alt="join us"
                  imgStyle={{ objectFit: 'contain' }}
                  css={joinSectionStyles.image}
                />
                <InView threshold={0.2} triggerOnce={true}>
                  {({ ref, inView }) => (
                    <motion.div
                      ref={ref}
                      initial={{ opacity: 0, y: '-20px' }}
                      animate={{ opacity: inView ? 1 : 0, y: 0 }}
                      transition={{ ease: 'linear', duration: 0.5, delay: 0.4 }}
                    >
                      <p css={joinSectionStyles.title}>Makers, join us!</p>
                      <p css={joinSectionStyles.description}>
                        Do you have a 3D printer you want to put to good use?
                        Use your skills to change people's lives!
                      </p>
                      <Button
                        variant="light"
                        icon={<ArrowRight />}
                        size="lrg"
                        href="https://i3vowt2twow.typeform.com/to/jgAp7UZX"
                      >
                        Learn more
                      </Button>
                    </motion.div>
                  )}
                </InView>
              </div>
            </motion.div>
          )}
        </InView>

        <InView threshold={0.2} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '-20px' }}
              animate={{ opacity: inView ? 1 : 0, y: 0 }}
              transition={{ ease: 'linear', duration: 0.5, delay: 0.6 }}
            >
              <div css={joinSectionStyles.sectionWrapper}>
                <GatsbyImage
                  {...img2.childImageSharp}
                  alt="need a hand"
                  imgStyle={{ objectFit: 'contain' }}
                  css={joinSectionStyles.image}
                />
                <InView threshold={0.2} triggerOnce={true}>
                  {({ ref, inView }) => (
                    <motion.div
                      ref={ref}
                      initial={{ opacity: 0, y: '-20px' }}
                      animate={{ opacity: inView ? 1 : 0, y: 0 }}
                      transition={{ ease: 'linear', duration: 0.5, delay: 0.6 }}
                    >
                      <p css={joinSectionStyles.title}>Need a hand?</p>
                      <p css={joinSectionStyles.description}>
                        Get a 3D printed prosthetic hand custom made for you or
                        your child by a certified maker for free!
                      </p>

                      <Button
                        variant="light"
                        icon={<ArrowRight />}
                        size="lrg"
                        href="https://i3vowt2twow.typeform.com/to/UPlXx0i4"
                      >
                        Learn more
                      </Button>
                    </motion.div>
                  )}
                </InView>
              </div>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
  );
};
