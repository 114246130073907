import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  background-color: hsl(var(--color-black-1));
  padding: 60px 0;

  @media ${breakpoints.medium} {
    padding: 120px 0;
  }
`;

const title = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('large')};
  ${lineHeight('large')};

  color: ${color('white', 11)};
  padding-bottom: var(--spacing-med);

  @media ${breakpoints.medium} {
    ${fontSize('xLarge')};
    ${lineHeight('xLarge')};
  }
`;

const description = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('base')};
  ${lineHeight('base')};

  color: hsla(var(--color-white-11), 80%);
`;

const sectionTitle = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')}
  ${lineHeight('xLarge')};

  color: ${color('white', 11)};

  @media ${breakpoints.medium} {
    max-width: 70%;
    ${fontSize('xxxLarge')}
    ${lineHeight('xxxLarge')};
  }
`;

const importantText = css`
  font-family: ${fontFamily.supremeBold};
  font-weight: ${fontWeight.bold};
  color: ${color('primary', 5)};
`;

const content = css`
  ${grid.config.cardGrid};
  align-items: flex-start;
  padding-top: var(--spacing-huge);

  @media ${breakpoints.medium} {
    padding-top: calc(var(--unit) * 30);
  }
`;

const textWrapper = css`
  padding-bottom: var(--spacing-xxxlrg);
  display: flex;
`;

const container = css``;

const number = css`
  font-family: ${fontFamily.supremeBold};
  font-weight: ${fontWeight.bold};

  ${fontSize('small')}
  ${lineHeight('small')};

  color: ${color('border', 8)};

  padding-top: var(--spacing-lrg);
  padding-right: var(--spacing-xxlrg);
`;

export const infoStyles = {
  root,
  title,
  description,
  sectionTitle,
  importantText,
  textWrapper,
  content,
  container,
  number,
};
