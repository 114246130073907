import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'utils';
import { SequenceList } from '.';
import { sequenceStyles } from '..';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';

export const SequenceSection: React.FC = () => {
  const [isActive, setIsActive] = useState<string>('0');
  const [visibleSlides, setVisibleSlides] = useState<number>(1.5);
  const [width] = useWindowSize();

  useEffect(() => {
    if (width >= 1024) if (visibleSlides !== 1) setVisibleSlides(1);
    if (width < 1024) if (visibleSlides !== 1.5) setVisibleSlides(1.5);
  }, [width]);

  const sequenceQuery = graphql`
    query SequenceQuery {
      image1: file(relativePath: { eq: "sequence/TestedAndProven.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      image2: file(relativePath: { eq: "sequence/CompletlyMechanical.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      image3: file(relativePath: { eq: "sequence/EasyToMake.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      image4: file(relativePath: { eq: "sequence/BuildInOneDay.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const img1 = useStaticQuery(sequenceQuery).image1;
  const img2 = useStaticQuery(sequenceQuery).image2;
  const img3 = useStaticQuery(sequenceQuery).image3;
  const img4 = useStaticQuery(sequenceQuery).image4;

  return (
    <div css={sequenceStyles.root}>
      <InView threshold={0.15} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.h2
            ref={ref}
            initial={{ opacity: 0, y: '-20px' }}
            animate={{ opacity: inView ? 1 : 0, y: 0 }}
            transition={{ ease: 'linear', duration: 0.5, delay: 0.2 }}
            css={sequenceStyles.sectionTitle}
          >
            The Maker Hand is a uniquely useful prosthesis but it is also
            remarkably simple to produce.
          </motion.h2>
        )}
      </InView>
      <div css={sequenceStyles.content}>
        <div css={sequenceStyles.sequenceWrapper}>
          <InView threshold={0.15} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 0.4 }}
              >
                <SequenceList
                  id="0"
                  title="Tested and proven"
                  description="Decisive winner at the official Cybathlon 2020 competition"
                  isActive={isActive == '0'}
                  setActive={setActive}
                />
              </motion.div>
            )}
          </InView>
          <InView threshold={0.15} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 0.6 }}
              >
                <SequenceList
                  id="1"
                  title="Completely mechanical"
                  description="Voluntary opening style of prothesis with no electronic parts"
                  isActive={isActive == '1'}
                  setActive={setActive}
                />
              </motion.div>
            )}
          </InView>
          <InView threshold={0.15} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 0.8 }}
              >
                <SequenceList
                  id="2"
                  title="Easy to make"
                  description="Built with any 3D printer and readily available materials"
                  isActive={isActive == '2'}
                  setActive={setActive}
                />
              </motion.div>
            )}
          </InView>
          <InView threshold={0.15} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 1 }}
              >
                <SequenceList
                  id="3"
                  title="Build in one day"
                  description="3D print in one day, assemble in a few hours"
                  isActive={isActive == '3'}
                  setActive={setActive}
                />
              </motion.div>
            )}
          </InView>
        </div>

        <div css={sequenceStyles.pictureWrapper}>
          <InView threshold={0.15} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 0.7 }}
              >
                <CarouselProvider
                  visibleSlides={visibleSlides}
                  naturalSlideWidth={100}
                  naturalSlideHeight={100}
                  totalSlides={4}
                  orientation={visibleSlides == 1 ? 'vertical' : 'horizontal'}
                  isPlaying={false}
                  currentSlide={parseInt(isActive)}
                >
                  <Slider css={sequenceStyles.slider}>
                    <Slide index={0}>
                      <div css={sequenceStyles.carousel}>
                        <GatsbyImage
                          {...img1.childImageSharp}
                          alt="makerhand"
                          imgStyle={{ objectFit: 'contain' }}
                          css={sequenceStyles.image}
                        />
                        <p css={sequenceStyles.title}>Tested and proven</p>
                        <p css={sequenceStyles.description}>
                          Decisive winner at the official Cybathlon 2020
                          competition
                        </p>
                      </div>
                    </Slide>
                    <Slide index={1}>
                      <div css={sequenceStyles.carousel}>
                        <GatsbyImage
                          {...img2.childImageSharp}
                          alt="makerhand"
                          imgStyle={{ objectFit: 'contain' }}
                          css={sequenceStyles.image}
                        />
                        <p css={sequenceStyles.title}>Completely mechanical</p>
                        <p css={sequenceStyles.description}>
                          Voluntary opening style of prothesis with no
                          electronic parts
                        </p>
                      </div>
                    </Slide>
                    <Slide index={2}>
                      <div css={sequenceStyles.carousel}>
                        <GatsbyImage
                          {...img3.childImageSharp}
                          alt="makerhand"
                          imgStyle={{ objectFit: 'contain' }}
                          css={sequenceStyles.image}
                        />
                        <p css={sequenceStyles.title}>Easy to make</p>
                        <p css={sequenceStyles.description}>
                          Built with any 3D printer and readily available
                          materials
                        </p>
                      </div>
                    </Slide>
                    <Slide index={3}>
                      <div css={sequenceStyles.carousel}>
                        <GatsbyImage
                          {...img4.childImageSharp}
                          alt="makerhand"
                          imgStyle={{ objectFit: 'contain' }}
                          css={sequenceStyles.image}
                        />
                        <p css={sequenceStyles.title}>Build in one day</p>
                        <p css={sequenceStyles.description}>
                          3D print in one day, assemble in a few hours
                        </p>
                      </div>
                    </Slide>
                  </Slider>
                </CarouselProvider>
              </motion.div>
            )}
          </InView>
        </div>
      </div>
    </div>
  );

  function setActive(id: string) {
    setIsActive(id);
  }
};
