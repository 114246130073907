import React from 'react';
import { videoStyles } from '..';

export const VideoSection: React.FC = () => (
  <iframe
    css={videoStyles.iframe}
    src="https://www.youtube.com/embed/4bSzDdNAV48?rel=0&controls=0"
    title="Cybathlon 2020 Finals"
    frameBorder="0"
    loading="lazy"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
);
