import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  padding: 60px 20px;

  @media ${breakpoints.custom800} {
    padding-left: 60px;
  }

  @media ${breakpoints.custom1024} {
    padding-left: 102px;
  }

  @media ${breakpoints.large} {
    padding: 120px 112px;
  }
`;

const sectionTitle = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('large')};
  ${lineHeight('large')};

  color: ${color('text', 2)};

  padding-bottom: var(--spacing-huge);

  @media ${breakpoints.custom800} {
    ${fontSize('xLarge')};
    ${lineHeight('xLarge')};
  }

  @media ${breakpoints.custom1024} {
    max-width: 65%;
    ${fontSize('xxLarge')};
    ${lineHeight('xxLarge')};
  }
`;

const content = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const pictureWrapper = css`
  width: 100%;
  height: 100%;

  @media ${breakpoints.custom1024} {
    width: 50%;
  }
`;

const sequenceWrapper = css`
  display: none;

  @media ${breakpoints.custom1024} {
    display: flex;
    flex-direction: column;
    max-width: 45%;
    height: 100%;
  }

  @media ${breakpoints.large} {
    max-width: 35%;
  }
`;

const title = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('large')};
  ${lineHeight('large')};

  color: ${color('text', 2)};

  padding: var(--spacing-xxlrg) 0;

  @media ${breakpoints.custom1024} {
    display: none;
  }
`;

const description = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('medium')};
  ${lineHeight('medium')};

  color: ${color('textLight', 3)};

  @media ${breakpoints.custom1024} {
    display: none;
  }
`;

const carousel = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  padding-right: var(--spacing-lrg);

  @media ${breakpoints.custom1024} {
    padding-left: var(--spacing-lrg);
  }

  @media ${breakpoints.large} {
    padding-right: 0;
  }
`;

const image = css`
  max-height: 250px;

  @media ${breakpoints.small} {
    max-height: 300px;
  }

  @media ${breakpoints.medium} {
    max-height: 620px;
  }
`;

const slider = css`
  min-height: 400px;

  @media ${breakpoints.small} {
    min-height: 380px;
  }

  @media ${breakpoints.custom600} {
    min-height: 500px;
  }
`;

export const sequenceStyles = {
  root,
  sectionTitle,
  content,
  title,
  description,
  pictureWrapper,
  sequenceWrapper,
  carousel,
  image,
  slider,
};
