import { HorizontalLine } from 'components';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'utils';
import { handSequenceListStyles } from '..';

interface Props {
  id: string;
  title: string;
  description: string;
  isActive: boolean;
  number: string;
  setActive: (id: string) => void;
}

export const HandSequenceList: React.FC<Props> = ({
  id,
  title,
  description,
  number,
  setActive,
  isActive,
}) => {
  const [showHorizontalLine, setShowHorizontalLine] = useState(false);

  const [width] = useWindowSize();

  useEffect(() => {
    if (width >= 1024) if (!showHorizontalLine) setShowHorizontalLine(true);
    if (width < 1024) if (showHorizontalLine) setShowHorizontalLine(false);
  }, [width]);
  return (
    <div onMouseOver={changeActive} css={handSequenceListStyles.root(isActive)}>
      <div css={handSequenceListStyles.container}>
        <div css={handSequenceListStyles.wrapper}>
          {showHorizontalLine && <HorizontalLine isActive={isActive} />}
          <p css={handSequenceListStyles.number}>{number}</p>
          <p css={handSequenceListStyles.title}>{title}</p>
        </div>
        <div>
          <p css={handSequenceListStyles.description}>{description}</p>
        </div>
      </div>
    </div>
  );

  function changeActive() {
    if (showHorizontalLine) setActive(id);
  }
};
