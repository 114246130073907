import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = (isActive: boolean) => css`
  padding-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.custom1024} {
    ${!isActive && `opacity: 0.4;`}
    cursor: pointer;
  }
`;

const wrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${breakpoints.custom1024} {
    margin-left: -80px;
  }

  padding-bottom: var(--spacing-med);
`;

const title = css`
  font-family: ${fontFamily.supremeBold};
  font-weight: ${fontWeight.bold};

  ${fontSize('medium')};
  ${lineHeight('medium')};

  color: ${color('text', 2)};
`;

const description = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('base')};
  ${lineHeight('base')};

  color: ${color('textLight', 3)};

  @media ${breakpoints.medium} {
    ${fontSize('base')};
    ${lineHeight('base')};
  }

  padding-left: 30px;
`;

const number = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('tiny')};
  ${lineHeight('tiny')};

  color: ${color('text', 2)};

  @media ${breakpoints.medium} {
    ${fontSize('small')};
    ${lineHeight('small')};
  }

  padding-right: var(--spacing-lrg);
`;

const container = css`
  display: flex;
  flex-direction: column;
`;

export const handSequenceListStyles = {
  root,
  wrapper,
  title,
  description,
  number,
  container,
};
