import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  background-color: ${color('black', 1)};
  padding: 120px 0;
`;

const wrapper = css`
  ${grid.config.growGrid};
  align-items: flex-start;
`;

const title = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('medium')};
  ${lineHeight('medium')};
  color: ${color('white', 11)};

  padding-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.large} {
    max-width: 80%;
    padding-bottom: var(--spacing-xlrg);
  }
`;

const sectionTitle = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};

  color: ${color('white', 11)};

  padding-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.medium} {
    ${fontSize('xxLarge')};
    ${lineHeight('xxLarge')};
  }

  @media ${breakpoints.large} {
    max-width: 80%;
    padding-bottom: 0;

    ${fontSize('xxxLarge')};
    ${lineHeight('xxxLarge')};
  }
`;

export const growStyles = { root, title, sectionTitle, wrapper };
