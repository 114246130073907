import { Container } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { HandSequenceList } from '.';
import { handSequenceStyles } from '..';
import { HandCircle } from 'assets/icons/HandCircle';
import { useWindowSize } from 'utils';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';

export const HandSequence: React.FC = () => {
  const [isActive, setIsActive] = useState<string>('1');
  const [position, setPosition] = useState<'absolute' | 'relative'>('relative');

  const [width] = useWindowSize();

  useEffect(() => {
    if (width >= 1024) if (position !== 'absolute') setPosition('absolute');
    if (width < 1024) if (position !== 'relative') setPosition('relative');
  }, [width]);

  const handSequenceQuery = graphql`
    query HadnSequenceQuery {
      file(relativePath: { eq: "HandSequence.png" }) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const handSequencePicture = useStaticQuery(handSequenceQuery);

  return (
    <div css={handSequenceStyles.root}>
      <Container css={handSequenceStyles.container}>
        <InView threshold={0.2} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '-20px' }}
              animate={{ opacity: inView ? 1 : 0, y: 0 }}
              transition={{ ease: 'linear', duration: 0.5, delay: 0.2 }}
              css={handSequenceStyles.sectionTitle}
            >
              Simple to produce, yet supremely functional
            </motion.h2>
          )}
        </InView>
        {/* <p css={handSequenceStyles.description}>
        Our prosthetic hands are easy to make since they are built with any 3d
        printer and readily available materials. It can be built in one day
          since their assembling lasts few hours.
        </p> */}
        <div css={handSequenceStyles.content}>
          <div>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 0.4 }}
                >
                  <HandSequenceList
                    id="1"
                    title="Silicone fingerpads"
                    description="Provide a soft touch but a very safe and firm grip"
                    isActive={isActive === '1'}
                    number={'01'}
                    setActive={setActive}
                  />
                </motion.div>
              )}
            </InView>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 0.6 }}
                >
                  <HandSequenceList
                    id="2"
                    title="Automatic locking grip"
                    description="Passive locking mechanism in each individual finger."
                    isActive={isActive === '2'}
                    number={'02'}
                    setActive={setActive}
                  />
                </motion.div>
              )}
            </InView>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 0.8 }}
                >
                  <HandSequenceList
                    id="3"
                    title="Three thumb positions"
                    description="Provide an array of useful grips for every situation"
                    isActive={isActive === '3'}
                    number={'03'}
                    setActive={setActive}
                  />
                </motion.div>
              )}
            </InView>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 1 }}
                >
                  <HandSequenceList
                    id="4"
                    title="Adjustable grip strength"
                    description="Instantly change your gripping power by simply thightening the bands"
                    isActive={isActive === '4'}
                    number={'04'}
                    setActive={setActive}
                  />
                </motion.div>
              )}
            </InView>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 1.2 }}
                >
                  <HandSequenceList
                    id="5"
                    title="Unique socket design"
                    description="Utilises remaining forearm rotation to rotate the prosthetic hand"
                    isActive={isActive === '5'}
                    number={'05'}
                    setActive={setActive}
                  />
                </motion.div>
              )}
            </InView>
          </div>

          <div css={handSequenceStyles.imageWrapper}>
            <GatsbyImage
              {...handSequencePicture.file.childImageSharp}
              alt="makerhand"
              style={{ position: position }}
              css={handSequenceStyles.hand}
            />
            <HandCircle
              id="1"
              css={handSequenceStyles.firstDot}
              isActive={isActive === '1'}
              onHover={setActive}
            />
            <HandCircle
              id="2"
              css={handSequenceStyles.secondDot}
              isActive={isActive === '2'}
              onHover={setActive}
            />
            <HandCircle
              id="3"
              css={handSequenceStyles.thirdDot}
              isActive={isActive === '3'}
              onHover={setActive}
            />
            <HandCircle
              id="4"
              css={handSequenceStyles.fourthDot}
              isActive={isActive === '4'}
              onHover={setActive}
            />
            <HandCircle
              id="5"
              css={handSequenceStyles.fifthDot}
              isActive={isActive === '5'}
              onHover={setActive}
            />
          </div>
        </div>
      </Container>
    </div>
  );

  function setActive(id: string) {
    setIsActive(id);
  }
};
