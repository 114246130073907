import { Container } from 'components';
import React from 'react';
import { infoStyles } from '..';

import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

export const InfoSection: React.FC = () => {
  return (
    <div css={infoStyles.root}>
      <Container>
        <div css={infoStyles.container}>
          <InView threshold={0.2} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.p
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 0.2 }}
                css={infoStyles.sectionTitle}
              >
                We <span css={infoStyles.importantText}>teach</span> volunteers
                how to <span css={infoStyles.importantText}>build</span> hands
                and <span css={infoStyles.importantText}>connect</span> them
                with people in need through our platform.
              </motion.p>
            )}
          </InView>
          <div css={infoStyles.content}>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 0.4 }}
                  css={infoStyles.textWrapper}
                >
                  <p css={infoStyles.number}>01</p>
                  <div>
                    <p css={infoStyles.title}>Application</p>
                    <p css={infoStyles.description}>
                      An amputee uploads pictures of their affected limb
                    </p>
                  </div>
                </motion.div>
              )}
            </InView>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 0.6 }}
                  css={infoStyles.textWrapper}
                >
                  <p css={infoStyles.number}>02</p>
                  <div>
                    <p css={infoStyles.title}>Customization</p>
                    <p css={infoStyles.description}>
                      We design a custom Makerhand specifically for the amputee.
                    </p>
                  </div>
                </motion.div>
              )}
            </InView>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 0.8 }}
                  css={infoStyles.textWrapper}
                >
                  <p css={infoStyles.number}>03</p>
                  <div>
                    <p css={infoStyles.title}>Manufacturing</p>
                    <p css={infoStyles.description}>
                      A certified maker prints and assembles the hand for them
                    </p>
                  </div>
                </motion.div>
              )}
            </InView>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 1 }}
                  css={infoStyles.textWrapper}
                >
                  <p css={infoStyles.number}>04</p>
                  <div>
                    <p css={infoStyles.title}>Fitting</p>
                    <p css={infoStyles.description}>
                      The maker fits the hand for the amputee.
                    </p>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </Container>
    </div>
  );
};
