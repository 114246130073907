import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  background-color: hsl(var(--color-bgLight-10));
  padding-top: 60px;

  @media ${breakpoints.custom1024} {
    padding: 120px 0;
  }
`;

const sectionTitle = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};

  color: ${color('text', 2)};

  padding-bottom: var(--spacing-huge);

  @media ${breakpoints.medium} {
    ${fontSize('xxLarge')};
    ${lineHeight('xxLarge')};
  }

  @media ${breakpoints.custom1024} {
    max-width: 60%;

    ${fontSize('xxxLarge')};
    ${lineHeight('xxxLarge')};
  }
`;

const content = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${breakpoints.custom1024} {
    flex-direction: row;
  }
`;

const container = css`
  position: relative;
  display: flex;

  flex-direction: column;
  margin: 0 auto;

  transition: all 0.5s;
  max-width: 1440px;

  padding: 0 20px;

  @media ${breakpoints.medium} {
    padding: 0 120px;
  }

  @media ${breakpoints.custom1024} {
    padding-left: 132px;
    padding-right: 32px;
  }

  @media ${breakpoints.large} {
    padding: 0 142px;
  }
`;

const imageWrapper = css`
  display: flex;
  justify-content: center;
`;

const hand = css`
  width: 200px;
  height: 100%;
  position: relative;

  @media ${breakpoints.custom1024} {
    width: 300px;
    position: absolute;
    bottom: -120px;
    width: 100%;
    max-width: 330px;
    height: auto;
    right: 40px;
  }

  @media ${breakpoints.large} {
    right: 162px;
  }
`;

const firstDot = css`
  position: absolute;
  top: -50px;
  right: 310px;
  cursor: pointer;

  display: none;

  @media ${breakpoints.custom1024} {
    display: block;
    right: 165px;
  }

  @media ${breakpoints.large} {
    right: 285px;
  }
`;

const secondDot = css`
  position: absolute;
  top: 170px;
  cursor: pointer;
  display: none;

  @media ${breakpoints.custom1024} {
    display: block;
    right: 250px;
  }

  @media ${breakpoints.large} {
    right: 380px;
  }
`;

const thirdDot = css`
  position: absolute;
  top: 240px;
  cursor: pointer;
  display: none;

  @media ${breakpoints.custom1024} {
    display: block;
    right: 110px;
  }

  @media ${breakpoints.large} {
    right: 230px;
  }
`;

const fourthDot = css`
  position: absolute;
  top: 450px;
  right: 270px;
  cursor: pointer;
  display: none;

  @media ${breakpoints.custom1024} {
    display: block;
    right: 140px;
  }

  @media ${breakpoints.large} {
    right: 265px;
  }
`;

const fifthDot = css`
  position: absolute;
  top: 700px;
  right: 270px;
  cursor: pointer;
  display: none;

  @media ${breakpoints.custom1024} {
    display: block;
    right: 178px;
  }

  @media ${breakpoints.large} {
    right: 300px;
  }
`;

const description = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('base')};
  ${lineHeight('base')};

  color: ${color('text', 2)};

  padding-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.medium} {
    ${fontSize('medium')};
    ${lineHeight('medium')};
  }

  @media ${breakpoints.custom1024} {
    max-width: 50%;

    ${fontSize('large')};
    ${lineHeight('large')};
  }
`;

export const handSequenceStyles = {
  root,
  sectionTitle,
  description,
  content,
  imageWrapper,
  container,
  firstDot,
  secondDot,
  thirdDot,
  fourthDot,
  fifthDot,
  hand,
};
