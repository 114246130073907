import { SerializedStyles } from '@emotion/utils';
import React from 'react';

interface Props {
  className?: SerializedStyles;
  id: string;
  isActive: boolean;
  onHover: (id: string) => void;
}

export const HandCircle: React.FC<Props> = ({
  className,
  isActive,
  id,
  onHover,
}) => (
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={className}
    onMouseOver={() => onHover(id)}
  >
    <g filter="url(#filter0_d_1336:2348)">
      <circle
        cx="38"
        cy="33"
        r="17.5"
        fill={isActive ? '#05D095' : 'rgba(209, 209, 209, 0.9)'}
        stroke={isActive ? '#FFFFFF' : '#05D095'}
      />
      <circle cx="38" cy="33" r="5" fill={isActive ? '#FFFFFF' : '#05D095'} />
    </g>
    <defs>
      <filter
        id="filter0_d_1336:2348"
        x="0.363636"
        y="0.272727"
        width="75.2727"
        height="75.2727"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.90909" />
        <feGaussianBlur stdDeviation="9.81818" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1336:2348"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1336:2348"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
