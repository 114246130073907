import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  padding-top: 60px;

  @media ${breakpoints.medium} {
    padding: 120px 0;
  }
`;

const sectionTitle = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};
  padding-bottom: var(--spacing-xxxlrg);

  color: ${color('text', 2)};

  @media ${breakpoints.medium} {
    ${fontSize('xxLarge')};
    ${lineHeight('xxLarge')};
    padding-bottom: var(--spacing-huge);
  }
`;

const content = css`
  ${grid.config.joinGrid};
  align-items: flex-start;
`;

const title = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('large')};
  ${lineHeight('large')};

  @media ${breakpoints.custom970} {
    ${fontSize('xLarge')};
    ${lineHeight('xLarge')};
  }

  color: ${color('text', 2)};
  padding-top: var(--spacing-xxxlrg);
  padding-bottom: var(--spacing-xlrg);
`;

const description = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('base')};
  ${lineHeight('base')};

  color: ${color('textLight', 3)};
  padding-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.custom970} {
    ${fontSize('medium')};
    ${lineHeight('medium')};
  }
`;

const sectionWrapper = css`
  padding-bottom: var(--spacing-huge);

  @media ${breakpoints.medium} {
    max-width: 90%;
    padding-bottom: 0;
  }
`;

const image = css`
  width: 100%;
  height: 100%;
`;

export const joinSectionStyles = {
  sectionTitle,
  content,
  title,
  description,
  image,
  sectionWrapper,
  root,
};
