import { Button, Container } from 'components';
import React from 'react';
import { growStyles } from '..';
import { ArrowRight } from 'assets/icons/ArrowRight';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

export const GrowSection: React.FC = () => {
  return (
    <div css={growStyles.root}>
      <Container>
        <div css={growStyles.wrapper}>
          <div>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 0.2 }}
                  css={growStyles.sectionTitle}
                >
                  Join the good cause and support our work by donating
                </motion.p>
              )}
            </InView>
          </div>
          <div>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 0.4 }}
                  css={growStyles.title}
                >
                  Since we are a non-profit and we don’t charge anything for our
                  prostheses— we rely on your support so we can continue helping
                  those in need.
                </motion.p>
              )}
            </InView>
            <InView threshold={0.2} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '-20px' }}
                  animate={{ opacity: inView ? 1 : 0, y: 0 }}
                  transition={{ ease: 'linear', duration: 0.5, delay: 0.6 }}
                >
                  <Button
                    variant="primary"
                    href="https://donorbox.org/makerhand"
                    icon={<ArrowRight />}
                  >
                    Help us grow
                  </Button>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </Container>
    </div>
  );
};
