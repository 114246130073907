import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

const iframe = css`
  width: 100%;
  height: 320px;

  @media ${breakpoints.medium} {
    height: 500px;
  }

  @media ${breakpoints.custom1024} {
    height: 738px;
  }
`;

export const videoStyles = { iframe };
