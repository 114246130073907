import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  padding: 60px 0;
  display: flex;

  @media ${breakpoints.medium} {
    padding: 120px 0;
  }
`;

const title = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};

  color: ${color('text', 2)};

  @media ${breakpoints.medium} {
    ${fontSize('xxxLarge')};
    ${lineHeight('xxxLarge')};
  }

  padding: var(--spacing-xxxlrg) 0;
`;

const description = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('medium')};
  ${lineHeight('medium')};

  color: ${color('textLight', 3)};

  max-width: 60%;
`;

const wrapper = css`
  @media ${breakpoints.custom970} {
    width: 50%;
    margin-left: auto;
    margin-right: 0;
  }
`;

export const winnerStyles = { root, title, description, wrapper };
