import React from 'react';
import { winnerStyles } from '..';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

export const WinnerSection: React.FC = () => {
  return (
    <div css={winnerStyles.root}>
      <div css={winnerStyles.wrapper}>
        <InView threshold={0.2} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.p
              ref={ref}
              initial={{ opacity: 0, y: '-20px' }}
              animate={{ opacity: inView ? 1 : 0, y: 0 }}
              transition={{ ease: 'linear', duration: 0.5, delay: 0.2 }}
              css={winnerStyles.title}
            >
              Decisive winner at the official Cybathlon 2020 competition.
            </motion.p>
          )}
        </InView>
        <InView threshold={0.2} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.p
              ref={ref}
              initial={{ opacity: 0, y: '-20px' }}
              animate={{ opacity: inView ? 1 : 0, y: 0 }}
              transition={{ ease: 'linear', duration: 0.5, delay: 0.5 }}
              css={winnerStyles.description}
            >
              Check why are we best in the world when it comes to building
              prosthetic hands.
            </motion.p>
          )}
        </InView>
      </div>
    </div>
  );
};
