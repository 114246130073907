import 'pure-react-carousel/dist/react-carousel.es.css';

import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React, { useState } from 'react';
import { carouselStyles } from '..';
import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

export const CarouselSection: React.FC = () => {
  const [autoPlay, setAutoPlay] = useState<boolean>(true);

  const carouselQueries = graphql`
    query {
      hrvoje: file(relativePath: { eq: "testimonials/Hrvoje.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 99) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      krunoslav: file(relativePath: { eq: "testimonials/Krunoslav.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dominik: file(relativePath: { eq: "testimonials/Dominik.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const pictures = useStaticQuery(carouselQueries);

  const onMouseEnter = () => {
    if (autoPlay) setAutoPlay(false);
  };

  const onMouseLeave = () => {
    if (!autoPlay) setAutoPlay(true);
  };

  return (
    <div css={carouselStyles.root}>
      <div css={carouselStyles.wrapper}>
        <InView threshold={0.2} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.p
              ref={ref}
              initial={{ opacity: 0, y: '-20px' }}
              animate={{ opacity: inView ? 1 : 0, y: 0 }}
              transition={{ ease: 'linear', duration: 0.5, delay: 0.2 }}
              css={carouselStyles.title}
            >
              People’s thoughts on the Maker Hand
            </motion.p>
          )}
        </InView>
        <InView threshold={0.2} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '-20px' }}
              animate={{ opacity: inView ? 1 : 0, y: 0 }}
              transition={{ ease: 'linear', duration: 0.5, delay: 0.4 }}
            >
              <CarouselProvider
                visibleSlides={1}
                naturalSlideWidth={100}
                naturalSlideHeight={20}
                totalSlides={3}
                isPlaying={autoPlay}
                interval={5000}
                isIntrinsicHeight
              >
                <Slider
                  trayProps={{
                    onMouseEnter: onMouseEnter,
                    onMouseLeave: onMouseLeave,
                  }}
                >
                  <Slide index={0}>
                    <div css={carouselStyles.content}>
                      <GatsbyImage
                        {...pictures.hrvoje.childImageSharp}
                        alt="Hrvoje Kovac"
                        css={carouselStyles.image}
                      />
                      <div css={carouselStyles.carouselContent}>
                        <InView threshold={0.2} triggerOnce={true}>
                          {({ ref, inView }) => (
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: '-20px' }}
                              animate={{ opacity: inView ? 1 : 0, y: 0 }}
                              transition={{
                                ease: 'linear',
                                duration: 0.5,
                                delay: 0.4,
                              }}
                              css={carouselStyles.description}
                            >
                              I put the Maker Hand on the moment I get out of
                              bed - I don’t take it off the whole day.
                            </motion.p>
                          )}
                        </InView>
                        <InView threshold={0.2} triggerOnce={true}>
                          {({ ref, inView }) => (
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: '-20px' }}
                              animate={{ opacity: inView ? 1 : 0, y: 0 }}
                              transition={{
                                ease: 'linear',
                                duration: 0.5,
                                delay: 0.6,
                              }}
                              css={carouselStyles.name}
                            >
                              Hrvoje Kovač
                            </motion.p>
                          )}
                        </InView>
                        <InView threshold={0.2} triggerOnce={true}>
                          {({ ref, inView }) => (
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: '-20px' }}
                              animate={{ opacity: inView ? 1 : 0, y: 0 }}
                              transition={{
                                ease: 'linear',
                                duration: 0.5,
                                delay: 0.8,
                              }}
                              css={carouselStyles.role}
                            >
                              First hand tester
                            </motion.p>
                          )}
                        </InView>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={1}>
                    <div css={carouselStyles.content}>
                      <GatsbyImage
                        {...pictures.krunoslav.childImageSharp}
                        alt="Krunoslav Mihić"
                        css={carouselStyles.image}
                      />
                      <div css={carouselStyles.carouselContent}>
                        <InView threshold={0.2} triggerOnce={true}>
                          {({ ref, inView }) => (
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: '-20px' }}
                              animate={{ opacity: inView ? 1 : 0, y: 0 }}
                              transition={{
                                ease: 'linear',
                                duration: 0.5,
                                delay: 0.4,
                              }}
                              css={carouselStyles.description}
                            >
                              With the Maker Hand I can do anything, I can break
                              an egg with no problem!
                            </motion.p>
                          )}
                        </InView>
                        <InView threshold={0.2} triggerOnce={true}>
                          {({ ref, inView }) => (
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: '-20px' }}
                              animate={{ opacity: inView ? 1 : 0, y: 0 }}
                              transition={{
                                ease: 'linear',
                                duration: 0.5,
                                delay: 0.6,
                              }}
                              css={carouselStyles.name}
                            >
                              Krunoslav Mihić
                            </motion.p>
                          )}
                        </InView>
                        <InView threshold={0.2} triggerOnce={true}>
                          {({ ref, inView }) => (
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: '-20px' }}
                              animate={{ opacity: inView ? 1 : 0, y: 0 }}
                              transition={{
                                ease: 'linear',
                                duration: 0.5,
                                delay: 0.8,
                              }}
                              css={carouselStyles.role}
                            >
                              Cybathlon competitor
                            </motion.p>
                          )}
                        </InView>
                      </div>
                    </div>
                  </Slide>
                  <Slide index={2}>
                    <div css={carouselStyles.content}>
                      <GatsbyImage
                        {...pictures.dominik.childImageSharp}
                        alt="Dominik Nestić"
                        css={carouselStyles.image}
                      />
                      <div css={carouselStyles.carouselContent}>
                        <InView threshold={0.2} triggerOnce={true}>
                          {({ ref, inView }) => (
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: '-20px' }}
                              animate={{ opacity: inView ? 1 : 0, y: 0 }}
                              transition={{
                                ease: 'linear',
                                duration: 0.5,
                                delay: 0.4,
                              }}
                              css={carouselStyles.description}
                            >
                              I can't wait to bring them to school and show them
                              to all my friends!
                            </motion.p>
                          )}
                        </InView>
                        <InView threshold={0.2} triggerOnce={true}>
                          {({ ref, inView }) => (
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: '-20px' }}
                              animate={{ opacity: inView ? 1 : 0, y: 0 }}
                              transition={{
                                ease: 'linear',
                                duration: 0.5,
                                delay: 0.6,
                              }}
                              css={carouselStyles.name}
                            >
                              Dominik Nestić
                            </motion.p>
                          )}
                        </InView>
                        <InView threshold={0.2} triggerOnce={true}>
                          {({ ref, inView }) => (
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: '-20px' }}
                              animate={{ opacity: inView ? 1 : 0, y: 0 }}
                              transition={{
                                ease: 'linear',
                                duration: 0.5,
                                delay: 0.8,
                              }}
                              css={carouselStyles.role}
                            >
                              Main design inspiration
                            </motion.p>
                          )}
                        </InView>
                      </div>
                    </div>
                  </Slide>
                </Slider>

                <div css={carouselStyles.dotWrapper}>
                  <Dot slide={0} css={carouselStyles.dot}></Dot>
                  <Dot slide={1} css={carouselStyles.dot}></Dot>
                  <Dot slide={2} css={carouselStyles.dot}></Dot>
                </div>
              </CarouselProvider>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
  );
};
