import { Container } from 'components';
import { SEO } from 'components/seo';
import {
  CarouselSection,
  GrowSection,
  HandSequence,
  HeroSection,
  InfoSection,
  JoinSection,
  SequenceSection,
  VideoSection,
  WinnerSection,
} from 'modules/home';
import React from 'react';

const Homepage = () => (
  <>
    <SEO title="Home" />
    <HeroSection />

    <Container>
      <SequenceSection />
    </Container>

    <HandSequence />

    <Container>
      <JoinSection />
    </Container>
    <InfoSection />

    <Container>
      <WinnerSection />
    </Container>

    <VideoSection />

    <Container>
      <CarouselSection />
    </Container>
    <GrowSection />
  </>
);

export default Homepage;
