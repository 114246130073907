import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  padding: 60px 20px;

  @media ${breakpoints.medium} {
    padding: 120px 60px;
  }

  @media ${breakpoints.large} {
    padding: 120px 112px;
  }
`;

const wrapper = css`
  display: flex;
  flex-direction: column;
`;

const title = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('medium')};
  ${lineHeight('medium')};

  color: ${color('text', 2)};
  padding-bottom: var(--spacing-xxxlrg);
  @media ${breakpoints.medium} {
    ${fontSize('sLarge')};
    ${lineHeight('sLarge')};
    padding-bottom: var(--spacing-huge);
  }
`;

const description = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('large')};
  ${lineHeight('large')};

  color: ${color('text', 2)};
  padding-top: var(--spacing-xxxlrg);
  padding-bottom: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    ${fontSize('xLarge')};
    ${lineHeight('xLarge')};
  }
`;

const name = css`
  font-family: ${fontFamily.supremeBold};
  font-weight: ${fontWeight.bold};

  ${fontSize('base')};
  ${lineHeight('base')};

  color: ${color('text', 2)};
`;

const role = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('small')};
  ${lineHeight('small')};

  color: ${color('text', 2)};
`;

const dot = css`
  background-color: ${color('borderLight', 9)};

  width: 12px;
  height: 12px;
  border-radius: 50%;
  padding: 0;
  border: 0;
  margin: 0 var(--spacing-tny);

  &:disabled {
    background-color: ${color('primary', 5)};
    opacity: 1;
  }

  &:active {
    background-color: ${color('primary', 5)};
    opacity: 1;
  }
`;

const dotWrapper = css`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: var(--spacing-xxxlrg);
`;

const content = css`
  ${grid.config.cardGrid};
  align-items: center;
`;

const carouselContent = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const image = css`
  width: 100%;
  height: 100%;
  max-height: 620px;

  aspect-ratio: 2 / 3;
`;

export const carouselStyles = {
  root,
  title,
  description,
  name,
  role,
  wrapper,
  dot,
  dotWrapper,
  image,
  content,
  carouselContent,
};
