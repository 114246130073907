import { HorizontalLine } from 'components';
import React from 'react';
import { sequenceListStyles } from '..';

interface Props {
  id: string;
  title: string;
  description: string;
  isActive: boolean;
  setActive: (id: string) => void;
}

export const SequenceList: React.FC<Props> = ({
  id,
  title,
  description,
  setActive,
  isActive,
}) => {
  return (
    <div onMouseOver={changeActive} css={sequenceListStyles.root(isActive)}>
      <div css={sequenceListStyles.wrapper}>
        <HorizontalLine isActive={isActive} />
        <p css={sequenceListStyles.title}>{title}</p>
      </div>
      <p css={sequenceListStyles.description}>{description}</p>
    </div>
  );

  function changeActive() {
    setActive(id);
  }
};
