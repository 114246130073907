import React from 'react';

export const ArrowRight: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33325 8H14.6666"
      stroke="#05D095"
      strokeWidth="1.33333"
      strokeMiterlimit="10"
    />
    <path
      d="M10 3.33325L14.6667 7.99992L10 12.6666"
      stroke="#05D095"
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
