import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight, unit } from 'style';

const titleWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: var(--spacing-xlrg);
  padding-bottom: var(--spacing-huge);
  padding-right: var(--spacing-xlrg);
  padding-left: var(--spacing-xlrg);

  @media ${breakpoints.custom1024} {
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-huge) 0;
  }
`;

const title = css`
  font-family: ${fontFamily.supremeBold};
  font-weight: ${fontWeight.bold};
  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};
  color: ${color('text', 2)};
  max-width: 100%;

  @media ${breakpoints.medium} {
    ${fontSize('xxLarge')};
    ${lineHeight('xxLarge')};
    max-width: 80%;
  }

  @media ${breakpoints.custom1024} {
    max-width: 100%;
    ${fontSize('xxxLarge')};
    ${lineHeight('xxxLarge')};
  }

  @media ${breakpoints.large} {
    ${fontSize('huge')};
    ${lineHeight('huge')};
  }

  text-transform: uppercase;
`;

const text = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('base')};
  ${lineHeight('base')};

  color: ${color('textLight', 3)};

  max-width: 100%;

  @media ${breakpoints.small} {
    max-width: 60%;
  }

  @media ${breakpoints.medium} {
    ${fontSize('medium')};
    ${lineHeight('medium')};
  }

  @media ${breakpoints.custom1024} {
    max-width: 100%;
    padding: 0 ${unit * 18}px;
  }

  @media ${breakpoints.large} {
    padding: 0 ${unit * 24}px;
  }
`;

const photo = css`
  width: 100%;
`;

const root = css`
  background: linear-gradient(
    0,
    hsl(var(--color-white-11)) 25%,
    hsl(var(--color-bgLight-10)) 25%
  );
`;

const iframe = css`
  width: 100%;
  height: 320px;

  @media ${breakpoints.medium} {
    height: 500px;
  }

  @media ${breakpoints.custom1024} {
    height: 738px;
  }
`;

export const heroStyles = { root, titleWrapper, title, text, photo, iframe };
