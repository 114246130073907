import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { fontFamily, fontWeight } from 'style';

const title = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};

  color: ${color('text', 2)};
`;

const description = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('medium')};
  ${lineHeight('medium')};

  color: ${color('textLight', 3)};
`;

const wrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-bottom: var(--spacing-xlrg);
  margin-left: -80px;
`;

const root = (isActive: boolean) => css`
  cursor: pointer;
  padding-bottom: var(--spacing-xxxlrg);

  ${!isActive && `opacity: 0.4;`}
`;

export const sequenceListStyles = {
  title,
  description,
  wrapper,
  root,
};
