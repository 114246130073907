import { Container } from 'components';
import React from 'react';
import { heroStyles } from '..';
import { motion } from 'framer-motion';

export const HeroSection = () => {
  return (
    <div css={heroStyles.root}>
      <Container>
        <div css={heroStyles.titleWrapper}>
          <motion.h1
            initial={{ opacity: 0, y: '-20px' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ ease: 'linear', duration: 0.7, delay: 0.3 }}
            css={heroStyles.title}
          >
            The most functional prosthetic hand in the world.
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: '-20px' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ ease: 'linear', duration: 0.7, delay: 0.7 }}
            css={heroStyles.text}
          >
            The Maker Hand is designed to be made by volunteer 3D printing
            enthusiasts!
          </motion.p>
        </div>

        <iframe
          css={heroStyles.iframe}
          src="https://www.youtube.com/embed/Ph-etPakhJM?rel=0&controls=0"
          title="MakerHand"
          loading="lazy"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </Container>
    </div>
  );
};
